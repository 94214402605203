.buyzone {
    margin: auto;
    text-align: center;
    background-color: rgb(0, 0, 0, 0);
    box-shadow:  2px 8px rgba(0, 0, 0, 0) !important;
}

.buyzone h1 {
    color: white;
}

.efecto {
    box-shadow: 0 2px 8px rgba(223, 223, 223, 0.26);
    border-radius: 0px;
}

.nft {
    padding: 2rem;
    margin: 1rem auto;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    color:white;
}

.img_nft {
    width: 50%;
}

.divVideoInfo {
    min-width: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin:auto;
    pointer-events: none;
}

.videoInfo {
    width:100%;
    height:100%;
    position:absolute;
    left:0px;
    top:0px;
    overflow:hidden;
    transform: translateY(0px);
    pointer-events: none;
}

.infoBorder {
    border-color: rgba(0, 0, 0, 0.8) !important;
    padding: 0.5rem;
    background-color: rgba(28, 28, 28, 0.3) !important;
    height: 100% !important;
}