.boxStyle {
    border-color: rgba(0, 0, 0, 0.8) !important;
    padding: 1rem;
    background-color: rgba(28, 28, 28, 0.3) !important;
    color: white;
}

.efecto {
    box-shadow: 0 2px 8px rgba(223, 223, 223, 0.26);
    border-radius: 0px;
    margin-right: auto;
    margin-left: auto;
}