.container_DetailCard {
    background-image: url("https://darkearth.gg/wp-content/uploads/2022/03/planetas.jpg");
    /*background-color: rgb(0, 0, 0, 0.95);*/
    width: 100%;
    height: 100%;
    z-index: 4000;
    position: fixed;
    top: 0px;
    left: 0px;
    overflow-y: auto;
}

.cardInfoRow {
    margin: 0.5rem !important;
    height: 100%;
    
    
}

.container_background {
    background-color: rgb(0, 0, 0, 0.8);
    width: 98%;
    padding: 1.5rem;
    height: auto;
    border-radius: 10px;
}

.cardImageCol {
    height: 100%;
    margin-right: 1rem;
}

.cardImage {
    box-shadow: 0 0 15px rgba(223, 223, 223, 0.46);
    border-radius: 15px;
}

.cardImage video {
    border-radius: 15px !important;
}

.metadataRow {
    background-color: rgba(62, 62, 62, 0.86);
    box-shadow: 0 0 10px rgba(223, 223, 223, 0.26);
    border-radius: 10px;
    margin-left: 0.5rem !important;
    max-width: 90%;
    max-height: 50%;
    padding: 1rem;
    min-width: 17.5rem;
}

.buttonRow {
    position: relative;
    text-align: right;
}

.descriptionBox_DetailCard {
    color: white;
}

.tokenSerials {
    max-height: 20rem;
    overflow-y: auto;
}

.tokenInfoData {
    text-align: center;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    border: 2px solid #ddd;
    width: 100%;
}

.tokenInfoData td, .tokenInfoData th {
    border: 1px solid #ddd;
    padding: 8px;
}

.tokenInfoData tr:nth-child(even){background-color: #747474;}

.tokenInfoData tr:hover {
    background-color: rgb(170, 195, 31);
    color:black;
    font-weight: bold;
}

.tokenInfoData th {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    background-color: #212121;
    color: white;
}

.buttonClose {
    position: relative;
    bottom: 3%;
    right: 0;
    left: 0;
    text-align: center;
}