@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
}

/* -----------------------------------
// COLORES FONDO BÁSICOS
--------------------------------------- */

html {
  background: rgb(0, 0, 0);
  max-width: 100%;
  overflow-x: hidden;
}

main {
  background-color: rgba(0, 0, 0, 1);
  padding-top: 7rem;
  padding-bottom: 5rem;
}

body {
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  scrollbar-color: rgb(173, 173, 15) yellow;
  scrollbar-arrow-color: black;
}

/* -----------------------------------
// TARJETAS DE INFORMACIÓN
--------------------------------------- */

.ventas {
  width: 90%;
  max-width: 40rem;
  padding: 3rem;
  margin: 2rem auto;
  text-align: center;
  background-color: cornflowerblue;
}
.Header_main_header__1U-DA {
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(0, 0, 0, 0.5);
  padding: 0 2rem;
  color:white;
}

.Header_navbar-nav__3lqc1 .Header_dropdown-menu__-hJ0i.Header_show__35GIf {
  color: white;
  font-weight: bold;
  background-color: red !important;
}
  
.Header_main_header__1U-DA h1 {
  color: white;
}
  
.Header_nav__K0wQp ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
  
.Header_nav__K0wQp li {
  margin: 0;
  margin-left: 2rem;
  color:rgb(255, 255, 255);
}

.Header_nav__K0wQp a {
  color: white;
  text-decoration: none;
}
  
.Header_nav__K0wQp a:hover,
.Header_nav__K0wQp a:active {
  color: #ffffff;
}

.Header_img_header__2leQG {
  width: 7%;
}

.Header_navBackground__3-jE- {
  background-color: #000000;
}
.DropdownWallet_dropMenu__2O3c8 {
    color: black !important;
}

.DropdownWallet_dropMenuMobile__18vo7 {
    color: rgb(174, 204, 22) !important;
}

.DropdownWallet_buttonInvisible__gNF9C {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}
.BDLoader_isOkImg__hdKJ5 {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.Card_card__2SSyi {
    box-shadow: 0 0 10px rgba(223, 223, 223, 0.26);
    border-radius: 30px;
    /*background: white;*/
}

.Card_cardContainer__1-HBd {
    margin: 1rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
    color:white;
    display: inline-block;
}

.Card_cardImg__cpgNA {
    width: 90%;
    max-width: 35rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    border-radius: 0px !important;
    border-top-right-radius: 30px !important;
    border-top-left-radius: 30px !important;
    cursor: url('https://i.imgur.com/8KeJTzH.png'), pointer;
}

.Card_cardCountNumber__3IMNt {
    position: absolute;
    width: 3rem;
    height: 3rem;
    z-index: 5000;
    left: 2vw;
    top: 3.5vh;
    font-size: 1.8rem;
    border-radius: 100px;
    background-color: rgba(56, 56, 56, 0.813);
    box-shadow: 0px 0px 15px rgb(86, 210, 255) !important;
    border: 3px solid #37ebff;
    font-family: Courier New, monospace!important;
    font-weight: bolder;
}

.Card_cardLoading__34qIK {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    border-radius: 50px !important;
}

.Card_cardInfo__2C6Fw {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.3);
    display: inline-block;
    border-radius: 0px !important;
    border-bottom-right-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
}

.Card_centrador__2NzQh {
    position: inherit;
    
    height: 100%;
    text-align: center;
    margin: auto;
}

ul {
    justify-content: center !important;
}

.Card_selector__2MhVf {
    -webkit-filter: invert(1);
            filter: invert(1);
    margin-top: 2rem;
}

.Card_title_1__1jeac {
    font-size: 0.3rem;
}

.Card_title_2__2ygo_ {
    font-size: 0.45rem;
}

.Card_title_3__11_tH {
    font-size: 0.6rem;
}

.Card_title_4__3F3aF {
    font-size: 0.75rem;
}

.Card_title_5__3qBPL {
    font-size: 0.9rem;
}

.Card_title_6__1z7G6 {
    font-size: 1.05rem;
}

.Card_title_7__2GxFL {
    font-size: 1.2rem;
}

.Card_title_8__2rmLa {
    font-size: 1.35rem;
}

.Card_title_9__16tDj {
    font-size: 1.5rem;
}

.Card_title_10__1if40 {
    font-size: 1.65rem;
}
.NoConectado_info__3en_A {
    padding: 3rem;
    margin: auto;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    color:white;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

.NoConectado_espaciador__1Phv- {
    padding-bottom: 5%;
}

.NoConectado_divVideoInfo__1Z57o {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    min-width: 15rem;
    margin:auto;
    pointer-events: none;
}

.NoConectado_videoInfo__jatiq {
    width:100%;
    height:100%;
    position:absolute;
    left:0px;
    top:0px;
    overflow:hidden;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    /*pointer-events: none;*/
}

.CountdownSale_countStyle__175NS {
    background-color: rgba(255, 255, 255, 0.9);
    color:black;
    margin: auto;
    border-radius: 1rem;
    padding: 1rem;
    font-weight: bold;
    max-width: 50%;
}

.CountdownSale_countStyleMobile__1_CS8 {
    background-color: rgba(255, 255, 255, 0.9);
    color:black;
    margin: auto;
    border-radius: 1rem;
    padding: 1rem;
    font-weight: bold;
    width: 100%;
}
.InfoAccount_infoAccount__3ob9N {
    width: 100%;
    padding: 2rem;
    margin: 2rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0);
    color:white;
    display: inline-block;
}

.InfoAccount_infoPresale__3gvAR{
    width: 100%;
    padding: 2rem;
    text-align: center;
    background-color:rgba(0, 0, 0, 0.2);
    color:white;
    display: inline-block;
}

.InfoAccount_infoZone__3PPn5 {
    width: 100%;
    padding: 2rem;
    margin: 2rem auto;
    text-align: center;
    background-color: rgb(0, 0, 0, 0);
    box-shadow:  2px 8px rgba(0, 0, 0, 0) !important;
}

.InfoAccount_centrador__2X-vv {
    text-align: center;
    margin: auto;
    display: inline-flex;
    width: 100%;
    height: 100%;
}

.InfoAccount_infoBorder__18y_c {
    border-color: rgba(255, 255, 255, 0.5) !important;
    background-color: rgba(28, 28, 28, 0.3) !important;
    margin-right: 10px;
    border-radius: 15px !important;
    border-left: none !important;
    border-right: none !important;
}
.BuyZone_buyzone__2LT0j {
    margin: auto;
    text-align: center;
    background-color: rgb(0, 0, 0, 0);
    box-shadow:  2px 8px rgba(0, 0, 0, 0) !important;
}

.BuyZone_buyzone__2LT0j h1 {
    color: white;
}

.BuyZone_efecto__m4oLI {
    box-shadow: 0 2px 8px rgba(223, 223, 223, 0.26);
    border-radius: 0px;
}

.BuyZone_nft__3KkYY {
    padding: 2rem;
    margin: 1rem auto;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    color:white;
}

.BuyZone_img_nft__2heKo {
    width: 50%;
}

.BuyZone_divVideoInfo__2-VYa {
    min-width: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin:auto;
    pointer-events: none;
}

.BuyZone_videoInfo__b9Uf- {
    width:100%;
    height:100%;
    position:absolute;
    left:0px;
    top:0px;
    overflow:hidden;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    pointer-events: none;
}

.BuyZone_infoBorder__2kPwn {
    border-color: rgba(0, 0, 0, 0.8) !important;
    padding: 0.5rem;
    background-color: rgba(28, 28, 28, 0.3) !important;
    height: 100% !important;
}
.CenterLogo_img_logo__1lOFM {
    width: 50%;
    height: auto;
    text-align: center;
    padding: 3rem;
}

.CenterLogo_centradorPc__1d-yv{
    max-width: 500px;;
    margin: auto;
}

.CenterLogo_centrador__3HVKM{
    max-width: 75vw;
    margin: auto;
}
  
.CenterLogo_espaciador__1UK74 {
    padding-bottom: 2rem;
}

.Footer_imgfooter__3NrdT {
    height: 100px;
}

.Footer_fooBackground__1Z9y0 {
    background-color: rgba(0, 0, 0, 1);
}
.Loader_loaderDiv__1ACdt{
    position: absolute;
    padding-bottom: 15%;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: black;
}

.Loader_centrador__29B40 {
    margin: auto;
    padding-bottom: 10%;
}

.Loader_loader__5PsR_ {
    position:relative;
      width:30px;
      height:20px;
  
      -webkit-animation:Loader_infinite-spinning__3xKG4 4s infinite;
  
              animation:Loader_infinite-spinning__3xKG4 4s infinite;
      -webkit-animation-timing-function:linear;
              animation-timing-function:linear;
      -webkit-transform-origin:335% 500%;
              transform-origin:335% 500%;
  }
  
  @-webkit-keyframes Loader_infinite-spinning__3xKG4 {
      from {
          -webkit-transform:rotate(0deg);
                  transform:rotate(0deg);
      }
      to {
          -webkit-transform:rotate(360deg);
                  transform:rotate(360deg);
      }
  }
  
  @keyframes Loader_infinite-spinning__3xKG4 {
      from {
          -webkit-transform:rotate(0deg);
                  transform:rotate(0deg);
      }
      to {
          -webkit-transform:rotate(360deg);
                  transform:rotate(360deg);
      }
  }
  
  .Loader_moon__1Calf {
    position: relative;
    margin: auto;
    width: 200px;
    height: 200px;
    background: #c7cbd0;
    border-radius: 50%;
    box-shadow: inset -25px 0px 0 0px #9098a1;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }
  
  .Loader_moon__1Calf:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }


  li.Loader_listyle__387y0 {
    position: absolute;
    list-style: none;
    background: #737277;
    border-radius: 50%;
  }
  li:nth-child(1).Loader_listyle__387y0 {
    left: 25px;
    top: 60px;
    width: 50px;
    height: 50px;
    box-shadow: inset 6px -2px 0 0px #414043;
  }
  li:nth-child(2).Loader_listyle__387y0 {
    left: 150px;
    top: 50px;
    width: 25px;
    height: 25px;
    box-shadow: inset 3px -1px 0 0px #414043;
  }
  
  li:nth-child(3).Loader_listyle__387y0 {
    left: 100px;
    top: 150px;
    width: 25px;
    height: 25px;
    box-shadow: inset 3px -1px 0 0px #414043;
  }
  
  li:nth-child(4).Loader_listyle__387y0 {
    left: 50px;
    top: 150px;
    width: 12.5px;
    height: 12.5px;
    box-shadow: inset 2.4px -0.8px 0 0px #414043;
  }
  
  li:nth-child(5).Loader_listyle__387y0 {
    left: 87.5px;
    top: 16.66666667px;
    width: 12.5px;
    height: 12.5px;
    box-shadow: inset 2.4px -0.8px 0 0px #414043;
  }
  
  li:nth-child(6).Loader_listyle__387y0 {
    left: 114.28571429px;
    top: 80px;
    width: 12.5px;
    height: 12.5px;
    box-shadow: inset 2.4px -0.8px 0 0px #414043;
  }
  
  li:nth-child(7).Loader_listyle__387y0 {
    left: 181.81818182px;
    top: 100px;
    width: 12.5px;
    height: 12.5px;
    box-shadow: inset 2.4px -0.8px 0 0px #414043;
  }
  
.NoDisponible_info__3g3g1 {
    width: 90%;
    padding: 3rem 3rem;
    margin: 2rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0.5);
    color:white;
    display: inline-block;
    margin-right: 50px;
    margin: auto;
}

.NoDisponible_infoCard__2d244 {
    width: 90%;
    max-width: 40rem;
    padding: 3rem;
    margin: 2rem auto;
    text-align: justify;
    background-color: rgba(255, 255, 255, 0.5);
    display: inline-block;
}

.NoDisponible_centrador__2rC8U {
    position: inherit;
    
    height: 100%;
    text-align: center;
    margin: auto;
}

.NoDisponible_separador__1aCyV {
    padding-top: 5%;
}
.CapsuleView_infoCapsules__1QDh1 {
    max-width: 60rem;
    margin: 1rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
    color:white;
    display: inline-block;
}

.CapsuleView_capsule__1uP70 {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.1);
    display: inline-block;
    border-radius: 0px !important;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
}

.CapsuleView_capsule_mark__FRMKI {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(115, 115, 115, 0.178);
    display: inline-block;
    border-radius: 0px !important;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
}

.CapsuleView_infoCapsulesCard__1iM_7 {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.2);
    display: inline-block;
    border-radius: 0px !important;
    border-bottom-right-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
}

.CapsuleView_centrador__GCb6J {
    position: inherit;
    
    height: 100%;
    text-align: center;
    margin: auto;
}

.CapsuleView_checkbox__2A3Ld {
    float:right;
    height: 30px;
    width: 30px;
    background: #fff;
    border: 3px rgb(0, 119, 255) solid;
  }
  
  .CapsuleView_checkbox--active__2b1PQ {
    border-color: purple;
    background: purple;
  }
  
.Paginator_selector__2Ay_E {
    -webkit-filter: invert(1);
            filter: invert(1);
    margin-top: 2rem;
    align-content: center;
    margin-right: auto;
    margin-left: auto;
}

.Paginator_selector_video_apertura__eYjRt {
    width: 100%;
    bottom: 0;
    position: absolute;
    -webkit-filter: invert(1);
            filter: invert(1);
    z-index: 2000;
}
.MyCapsule_infoCapsules__2GYgO {
    max-width: 60rem;
    margin: 1rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
    color:white;
    display: inline-block;
}

.MyCapsule_capsule__1MFI_ {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.1);
    display: inline-block;
    border-radius: 0px !important;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;

}

.MyCapsule_infoCapsulesCard__33ZIX {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.2);
    display: inline-block;
    border-radius: 0px !important;
    border-bottom-right-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
}

.MyCapsule_centrador__2iKL4 {
    position: inherit;
    
    height: 100%;
    text-align: center;
    margin: auto;
}

.MyCapsule_checkbox__3jRb7 {
    float:right;
    height: 30px;
    width: 30px;
    background: #fff;
    border: 3px rgb(0, 119, 255) solid;
  }
  
  .MyCapsule_checkbox--active__3jM3U {
    border-color: purple;
    background: purple;
  }
  
.Market_infoCapsules__1-0OD {
    width: 90%;
    max-width: 60rem;
    padding: 3rem 3rem;
    margin: 2rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0.5);
    color:white;
    display: inline-block;
    margin-right: 50px;
}

.Market_infoCapsulesCard__6hymT {
    width: 90%;
    max-width: 40rem;
    padding: 3rem;
    margin: 2rem auto;
    text-align: justify;
    background-color: rgba(255, 255, 255, 0.5);
    display: inline-block;
}

.Market_centrador__2lAuO {
    position: inherit;
    
    height: 100%;
    text-align: center;
    margin: auto;
}
.Error404_infoCapsules__1SNVM {
    width: 90%;
    max-width: 60rem;
    padding: 3rem 3rem;
    margin: 2rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0.5);
    color:white;
    display: inline-block;
    margin-right: 50px;
}

.Error404_infoCapsulesCard__1kkXH {
    width: 90%;
    max-width: 40rem;
    padding: 3rem;
    margin: 2rem auto;
    text-align: justify;
    background-color: rgba(255, 255, 255, 0.5);
    display: inline-block;
}

.Error404_centrador__2LhZe {
    position: inherit;
    
    height: 100%;
    text-align: center;
    margin: auto;
}

.Error404_imgError__8HA4x{
    width: 90%;
    -webkit-filter: brightness(1);
            filter: brightness(1);
}
.MyCard_cardContainer__FrnA4 {
    margin: 1rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
    color:white;
    display: inline-block;
}

.MyCard_cardImg__3iukD {
    width: 90%;
    max-width: 35rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    border-radius: 0px !important;
    border-top-right-radius: 30px !important;
    border-top-left-radius: 30px !important;
}

.MyCard_cardLoading__3q-dv {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    border-radius: 50px !important;
}

.MyCard_cardInfo__1BHxn {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.3);
    display: inline-block;
    border-radius: 0px !important;
    border-bottom-right-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
}

.MyCard_centrador__39Ez7 {
    position: inherit;
    
    height: 100%;
    text-align: center;
    margin: auto;
}

ul {
    justify-content: center !important;
}

.MyCard_selector__1Vdz2 {
    -webkit-filter: invert(1);
            filter: invert(1);
    margin-top: 2rem;
}

.MyCard_title_1__2x0MG {
    font-size: 0.3rem;
}

.MyCard_title_2__1MHy1 {
    font-size: 0.45rem;
}

.MyCard_title_3__HAATa {
    font-size: 0.6rem;
}

.MyCard_title_4__2kC7h {
    font-size: 0.75rem;
}

.MyCard_title_5__1JjaQ {
    font-size: 0.9rem;
}

.MyCard_title_6__2DUMp {
    font-size: 1.05rem;
}

.MyCard_title_7__xHcgn {
    font-size: 1.2rem;
}

.MyCard_title_8__K8QcD {
    font-size: 1.35rem;
}

.MyCard_title_9__OiVSs {
    font-size: 1.5rem;
}

.MyCard_title_10__2-QDz {
    font-size: 1.65rem;
}
/*
.container_FilterCardView, .container_FilteredCardView {
    position: fixed;
    z-index: 3000;
    top: 20%;
}
*/
.FilterCard_container_FilteredCardView__3Zo1g {
    position: fixed;
    z-index: 3000;
    top: 20%;
    max-height: 4.4vh;
    width: 25%;
    text-align: left !important;
    left: 0;
}

/*
.container_FilterCardView > *{
    position: relative;
}
*/

.FilterCard_box_filter_FilterCardView__tq9FN {
    width: 22vw;
    height: 75vh;
    left: 0px;
    background-color: rgb(31, 31, 31, 0.90);
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
	overflow-y: auto; 
    min-width: 24rem;   
}
 
.FilterCard_box_filter_FilterCardView__tq9FN::-webkit-scrollbar
{
    width: 20px;
}

.FilterCard_box_filter_FilterCardView__tq9FN::-webkit-scrollbar-track
{
    background-color: transparent;
}
 
.FilterCard_box_filter_FilterCardView__tq9FN::-webkit-scrollbar-thumb
{
    border-radius: 20px;
    background-color: #282b2c;
    border: 6px solid transparent;
    background-clip: content-box;
}

.FilterCard_box_filter_FilterCardView__tq9FN::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

.FilterCard_btn_toggle_OpenFilterCardView__3Jdlz, .FilterCard_btn_toggle_CloseFilterCardView__R-usI {
    color: white;
    width: 7vw;
    min-width: 7rem;
}

.FilterCard_btn_toggle_OpenFilterCardView__3Jdlz {
    cursor:pointer;
}

.FilterCard_btn_toggle_CloseFilterCardView__R-usI {
    float: right;
}

.FilterCard_container_SearchAutocomplete__W8uur {
    width: 80%;
    margin: auto;
    margin-top: 1rem;
    text-align: center;
    z-index: 10000;
}

.FilterCard_container_filters__W9roV {
    width: 80%;
    margin: auto;
    margin-top: 1rem;
    text-align: center;
    z-index: -1;
}

.FilterCard_filter_titles__1Ibkj {
    color: #FFC300;
    font-style: italic;
}

.FilterCard_search_custom__3S3aM {
    position: relative;
    z-index: 10000;
}

.FilterCard_container_CurrentFilters__2Zr63 {
    position: relative;
    width: 80%;
    margin: left;
    margin-top: 1rem;
    text-align: center;
}



.DetailCard_container_DetailCard__25S2x {
    background-image: url("https://darkearth.gg/wp-content/uploads/2022/03/planetas.jpg");
    /*background-color: rgb(0, 0, 0, 0.95);*/
    width: 100%;
    height: 100%;
    z-index: 4000;
    position: fixed;
    top: 0px;
    left: 0px;
    overflow-y: auto;
}

.DetailCard_cardInfoRow__1yCEi {
    margin: 0.5rem !important;
    height: 100%;
    
    
}

.DetailCard_container_background__2qEpw {
    background-color: rgb(0, 0, 0, 0.8);
    width: 98%;
    padding: 1.5rem;
    height: auto;
    border-radius: 10px;
}

.DetailCard_cardImageCol__35G2e {
    height: 100%;
    margin-right: 1rem;
}

.DetailCard_cardImage__OdqKA {
    box-shadow: 0 0 15px rgba(223, 223, 223, 0.46);
    border-radius: 15px;
}

.DetailCard_cardImage__OdqKA video {
    border-radius: 15px !important;
}

.DetailCard_metadataRow__3DfGo {
    background-color: rgba(62, 62, 62, 0.86);
    box-shadow: 0 0 10px rgba(223, 223, 223, 0.26);
    border-radius: 10px;
    margin-left: 0.5rem !important;
    max-width: 90%;
    max-height: 50%;
    padding: 1rem;
    min-width: 17.5rem;
}

.DetailCard_buttonRow__3e-gN {
    position: relative;
    text-align: right;
}

.DetailCard_descriptionBox_DetailCard__65c3l {
    color: white;
}

.DetailCard_tokenSerials__1S2De {
    max-height: 20rem;
    overflow-y: auto;
}

.DetailCard_tokenInfoData__1y9ek {
    text-align: center;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    border: 2px solid #ddd;
    width: 100%;
}

.DetailCard_tokenInfoData__1y9ek td, .DetailCard_tokenInfoData__1y9ek th {
    border: 1px solid #ddd;
    padding: 8px;
}

.DetailCard_tokenInfoData__1y9ek tr:nth-child(even){background-color: #747474;}

.DetailCard_tokenInfoData__1y9ek tr:hover {
    background-color: rgb(170, 195, 31);
    color:black;
    font-weight: bold;
}

.DetailCard_tokenInfoData__1y9ek th {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    background-color: #212121;
    color: white;
}

.DetailCard_buttonClose__1UgEO {
    position: relative;
    bottom: 3%;
    right: 0;
    left: 0;
    text-align: center;
}
body{
    background-color:rgba(0, 0, 0, 0);
}

.VideoApertura_videoApertura_Container__24Zlt {
    text-align: center;
    background-color:rgba(0, 0, 0, 0);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
    color:white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1!important;
    overflow-y: hidden;
    overflow-x: hidden;
}

.VideoApertura_card_Container__1OMsx {
    z-index: 2000!important;
    position: absolute;
    /*height: 90.3vh;*/
    margin-top: 10.6vh;
    margin-left: 1.75vw!important;
}

.VideoApertura_card_Row__3txjt {
    justify-content: center;
    margin-bottom: 1vh;
}

.VideoApertura_card_Column__1uwBh {
    align-items: center;
    position: relative;
}

.VideoApertura_card_Img__2x9Y8 {
    position: relative;
    justify-content: center;
    width: 12vw;
}

.VideoApertura_btn_close_videoApertura__Y3yB5 {
    right: 1vw;
    top: 1vw;
    position: absolute;
    color: white;
    z-index: 2001;
}

.VideoApertura_loader__3c1Am {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 30%;
    text-align: center;
    font-size: 6rem;
    color: white;
}

.VideoApertura_giraMovil__nCrV4 {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    text-align: center;
    color: white;
    height: 100%;
    width: 100%;
}

.VideoApertura_test__2JGle {
    -webkit-filter: invert(1);
            filter: invert(1);
    overflow-y: hidden;
    overflow-x: hidden;
}
.Reward_buyzone__1SHD7 {
    margin: auto;
    text-align: center;
    background-color: rgb(0, 0, 0, 0);
    box-shadow:  2px 8px rgba(0, 0, 0, 0) !important;
    display: inline-block;
    margin: 1rem;
    align-content: center;
    align-items: center;
}

.Reward_efecto__9Cm-l {
    box-shadow: 0 2px 8px rgba(223, 223, 223, 0.26);
    border-radius: 0px;
}

.Reward_buyzone__1SHD7 h1 {
    color: white;
}

.Reward_nft__ncWOp {
    padding: 2rem;
    margin: 1rem auto;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    color:white;
}

.Reward_img_nft__21SL9 {
    width: 50%;
}

.Reward_divVideoInfo__AliK1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin:auto;
    pointer-events: none;
}

.Reward_videoInfo__3knpB {
    width:100%;
    height:100%;
    position:absolute;
    left:0px;
    top:0px;
    overflow:hidden;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    pointer-events: none;
}

.Reward_infoBorder__SFHra {
    border-color: rgba(0, 0, 0, 0.8) !important;
    padding: 1rem;
    background-color: rgba(28, 28, 28, 0.3) !important
}
.MyNFT_cardContainer__1sMTK {
    margin: 1rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
    color:white;
    display: inline-block;
}

.MyNFT_cardImg__1kbIz {
    width: 90%;
    max-width: 35rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    border-radius: 0px !important;
    border-top-right-radius: 30px !important;
    border-top-left-radius: 30px !important;
}

.MyNFT_cardLoading__3mLLf {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    border-radius: 50px !important;
}

.MyNFT_cardInfo__297p_ {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.3);
    display: inline-block;
    border-radius: 0px !important;
    border-bottom-right-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
}

.MyNFT_centrador__1JrS7 {
    position: inherit;
    
    height: 100%;
    text-align: center;
    margin: auto;
}

ul {
    justify-content: center !important;
}

.MyNFT_selector__UCJj3 {
    -webkit-filter: invert(1);
            filter: invert(1);
    margin-top: 2rem;
}

.MyNFT_title_1__1MVTX {
    font-size: 0.3rem;
}

.MyNFT_title_2__1ZSZU {
    font-size: 0.45rem;
}

.MyNFT_title_3__X43eH {
    font-size: 0.6rem;
}

.MyNFT_title_4__3JXKv {
    font-size: 0.75rem;
}

.MyNFT_title_5__1v3gq {
    font-size: 0.9rem;
}

.MyNFT_title_6__kta28 {
    font-size: 1.05rem;
}

.MyNFT_title_7__3cwI9 {
    font-size: 1.2rem;
}

.MyNFT_title_8__2_ORn {
    font-size: 1.35rem;
}

.MyNFT_title_9__1Hryv {
    font-size: 1.5rem;
}

.MyNFT_title_10__37Nin {
    font-size: 1.65rem;
}
.CardGallery_tamCard__1-p7z {
    width: 20% !important;
    padding: 0.3%;
    cursor: url('https://i.imgur.com/8KeJTzH.png'), pointer;
}
.DetailCollectionCard_container_DetailCard__2PRN- {
    background-image: url("https://darkearth.gg/wp-content/uploads/2022/03/planetas.jpg");
    /*background-color: rgb(0, 0, 0, 0.95);*/
    width: 100%;
    height: 100%;
    z-index: 4000;
    position: fixed;
    top: 0px;
    left: 0px;
}

.DetailCollectionCard_cardInfoRow__1RZfu {
    background-color: rgb(0, 0, 0, 0.8);
    margin: 1rem !important;
    height: 90%;
    padding: 2rem;
    border-radius: 10px;
}

.DetailCollectionCard_cardImageCol__3giII {
    height: 100%;
    margin-right: 4rem;
}

.DetailCollectionCard_cardImage__3Hw2Q {
    box-shadow: 0 0 15px rgba(223, 223, 223, 0.46);
    border-radius: 15px;
}

.DetailCollectionCard_cardImage__3Hw2Q video {
    border-radius: 15px !important;
}

.DetailCollectionCard_metadataRow__3e7pM {
    background-color: rgba(62, 62, 62, 0.86);
    box-shadow: 0 0 10px rgba(223, 223, 223, 0.26);
    border-radius: 10px;
    margin: 1rem;
    max-width: 75%;
    padding: 0.5rem;
}

.DetailCollectionCard_buttonRow__j7KZo {
    position: relative;
    text-align: right;
}

.DetailCollectionCard_descriptionBox_DetailCard__2U8VL {
    color: white;
}
.Referrals_boxStyle__3JQfC {
    border-color: rgba(0, 0, 0, 0.8) !important;
    padding: 1rem;
    background-color: rgba(28, 28, 28, 0.3) !important;
    color: white;
}

.Referrals_efecto__1ka4d {
    box-shadow: 0 2px 8px rgba(223, 223, 223, 0.26);
    border-radius: 0px;
    margin-right: auto;
    margin-left: auto;
}
