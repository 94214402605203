.infoCapsules {
    max-width: 60rem;
    margin: 1rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
    color:white;
    display: inline-block;
}

.capsule {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.1);
    display: inline-block;
    border-radius: 0px !important;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;

}

.infoCapsulesCard {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.2);
    display: inline-block;
    border-radius: 0px !important;
    border-bottom-right-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
}

.centrador {
    position: inherit;
    
    height: 100%;
    text-align: center;
    margin: auto;
}

.checkbox {
    float:right;
    height: 30px;
    width: 30px;
    background: #fff;
    border: 3px rgb(0, 119, 255) solid;
  }
  
  .checkbox--active {
    border-color: purple;
    background: purple;
  }
  