.info {
    padding: 3rem;
    margin: auto;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    color:white;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

.espaciador {
    padding-bottom: 5%;
}

.divVideoInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    min-width: 15rem;
    margin:auto;
    pointer-events: none;
}

.videoInfo {
    width:100%;
    height:100%;
    position:absolute;
    left:0px;
    top:0px;
    overflow:hidden;
    transform: translateY(0px);
    /*pointer-events: none;*/
}
