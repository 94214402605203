/*
.container_FilterCardView, .container_FilteredCardView {
    position: fixed;
    z-index: 3000;
    top: 20%;
}
*/
.container_FilteredCardView {
    position: fixed;
    z-index: 3000;
    top: 20%;
    max-height: 4.4vh;
    width: 25%;
    text-align: left !important;
    left: 0;
}

/*
.container_FilterCardView > *{
    position: relative;
}
*/

.box_filter_FilterCardView {
    width: 22vw;
    height: 75vh;
    left: 0px;
    background-color: rgb(31, 31, 31, 0.90);
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
	overflow-y: auto; 
    min-width: 24rem;   
}
 
.box_filter_FilterCardView::-webkit-scrollbar
{
    width: 20px;
}

.box_filter_FilterCardView::-webkit-scrollbar-track
{
    background-color: transparent;
}
 
.box_filter_FilterCardView::-webkit-scrollbar-thumb
{
    border-radius: 20px;
    background-color: #282b2c;
    border: 6px solid transparent;
    background-clip: content-box;
}

.box_filter_FilterCardView::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

.btn_toggle_OpenFilterCardView, .btn_toggle_CloseFilterCardView {
    color: white;
    width: 7vw;
    min-width: 7rem;
}

.btn_toggle_OpenFilterCardView {
    cursor:pointer;
}

.btn_toggle_CloseFilterCardView {
    float: right;
}

.container_SearchAutocomplete {
    width: 80%;
    margin: auto;
    margin-top: 1rem;
    text-align: center;
    z-index: 10000;
}

.container_filters {
    width: 80%;
    margin: auto;
    margin-top: 1rem;
    text-align: center;
    z-index: -1;
}

.filter_titles {
    color: #FFC300;
    font-style: italic;
}

.search_custom {
    position: relative;
    z-index: 10000;
}

.container_CurrentFilters {
    position: relative;
    width: 80%;
    margin: left;
    margin-top: 1rem;
    text-align: center;
}


