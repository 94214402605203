.img_logo {
    width: 50%;
    height: auto;
    text-align: center;
    padding: 3rem;
}

.centradorPc{
    max-width: 500px;;
    margin: auto;
}

.centrador{
    max-width: 75vw;
    margin: auto;
}
  
.espaciador {
    padding-bottom: 2rem;
}
