@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
}

/* -----------------------------------
// COLORES FONDO BÁSICOS
--------------------------------------- */

html {
  background: rgb(0, 0, 0);
  max-width: 100%;
  overflow-x: hidden;
}

main {
  background-color: rgba(0, 0, 0, 1);
  padding-top: 7rem;
  padding-bottom: 5rem;
}

body {
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  scrollbar-color: rgb(173, 173, 15) yellow;
  scrollbar-arrow-color: black;
}

/* -----------------------------------
// TARJETAS DE INFORMACIÓN
--------------------------------------- */

.ventas {
  width: 90%;
  max-width: 40rem;
  padding: 3rem;
  margin: 2rem auto;
  text-align: center;
  background-color: cornflowerblue;
}