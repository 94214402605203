.infoAccount {
    width: 100%;
    padding: 2rem;
    margin: 2rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0);
    color:white;
    display: inline-block;
}

.infoPresale{
    width: 100%;
    padding: 2rem;
    text-align: center;
    background-color:rgba(0, 0, 0, 0.2);
    color:white;
    display: inline-block;
}

.infoZone {
    width: 100%;
    padding: 2rem;
    margin: 2rem auto;
    text-align: center;
    background-color: rgb(0, 0, 0, 0);
    box-shadow:  2px 8px rgba(0, 0, 0, 0) !important;
}

.centrador {
    text-align: center;
    margin: auto;
    display: inline-flex;
    width: 100%;
    height: 100%;
}

.infoBorder {
    border-color: rgba(255, 255, 255, 0.5) !important;
    background-color: rgba(28, 28, 28, 0.3) !important;
    margin-right: 10px;
    border-radius: 15px !important;
    border-left: none !important;
    border-right: none !important;
}