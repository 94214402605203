.info {
    width: 90%;
    padding: 3rem 3rem;
    margin: 2rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0.5);
    color:white;
    display: inline-block;
    margin-right: 50px;
    margin: auto;
}

.infoCard {
    width: 90%;
    max-width: 40rem;
    padding: 3rem;
    margin: 2rem auto;
    text-align: justify;
    background-color: rgba(255, 255, 255, 0.5);
    display: inline-block;
}

.centrador {
    position: inherit;
    
    height: 100%;
    text-align: center;
    margin: auto;
}

.separador {
    padding-top: 5%;
}