.cardContainer {
    margin: 1rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
    color:white;
    display: inline-block;
}

.cardImg {
    width: 90%;
    max-width: 35rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    border-radius: 0px !important;
    border-top-right-radius: 30px !important;
    border-top-left-radius: 30px !important;
}

.cardLoading {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    border-radius: 50px !important;
}

.cardInfo {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.3);
    display: inline-block;
    border-radius: 0px !important;
    border-bottom-right-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
}

.centrador {
    position: inherit;
    
    height: 100%;
    text-align: center;
    margin: auto;
}

ul {
    justify-content: center !important;
}

.selector {
    filter: invert(1);
    margin-top: 2rem;
}

.title_1 {
    font-size: 0.3rem;
}

.title_2 {
    font-size: 0.45rem;
}

.title_3 {
    font-size: 0.6rem;
}

.title_4 {
    font-size: 0.75rem;
}

.title_5 {
    font-size: 0.9rem;
}

.title_6 {
    font-size: 1.05rem;
}

.title_7 {
    font-size: 1.2rem;
}

.title_8 {
    font-size: 1.35rem;
}

.title_9 {
    font-size: 1.5rem;
}

.title_10 {
    font-size: 1.65rem;
}