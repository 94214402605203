.container_DetailCard {
    background-image: url("https://darkearth.gg/wp-content/uploads/2022/03/planetas.jpg");
    /*background-color: rgb(0, 0, 0, 0.95);*/
    width: 100%;
    height: 100%;
    z-index: 4000;
    position: fixed;
    top: 0px;
    left: 0px;
}

.cardInfoRow {
    background-color: rgb(0, 0, 0, 0.8);
    margin: 1rem !important;
    height: 90%;
    padding: 2rem;
    border-radius: 10px;
}

.cardImageCol {
    height: 100%;
    margin-right: 4rem;
}

.cardImage {
    box-shadow: 0 0 15px rgba(223, 223, 223, 0.46);
    border-radius: 15px;
}

.cardImage video {
    border-radius: 15px !important;
}

.metadataRow {
    background-color: rgba(62, 62, 62, 0.86);
    box-shadow: 0 0 10px rgba(223, 223, 223, 0.26);
    border-radius: 10px;
    margin: 1rem;
    max-width: 75%;
    padding: 0.5rem;
}

.buttonRow {
    position: relative;
    text-align: right;
}

.descriptionBox_DetailCard {
    color: white;
}