body{
    background-color:rgba(0, 0, 0, 0);
}

.videoApertura_Container {
    text-align: center;
    background-color:rgba(0, 0, 0, 0);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
    color:white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1!important;
    overflow-y: hidden;
    overflow-x: hidden;
}

.card_Container {
    z-index: 2000!important;
    position: absolute;
    /*height: 90.3vh;*/
    margin-top: 10.6vh;
    margin-left: 1.75vw!important;
}

.card_Row {
    justify-content: center;
    margin-bottom: 1vh;
}

.card_Column {
    align-items: center;
    position: relative;
}

.card_Img {
    position: relative;
    justify-content: center;
    width: 12vw;
}

.btn_close_videoApertura {
    right: 1vw;
    top: 1vw;
    position: absolute;
    color: white;
    z-index: 2001;
}

.loader {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 30%;
    text-align: center;
    font-size: 6rem;
    color: white;
}

.giraMovil {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    text-align: center;
    color: white;
    height: 100%;
    width: 100%;
}

.test {
    filter: invert(1);
    overflow-y: hidden;
    overflow-x: hidden;
}