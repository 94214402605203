.countStyle {
    background-color: rgba(255, 255, 255, 0.9);
    color:black;
    margin: auto;
    border-radius: 1rem;
    padding: 1rem;
    font-weight: bold;
    max-width: 50%;
}

.countStyleMobile {
    background-color: rgba(255, 255, 255, 0.9);
    color:black;
    margin: auto;
    border-radius: 1rem;
    padding: 1rem;
    font-weight: bold;
    width: 100%;
}