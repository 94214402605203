.loaderDiv{
    position: absolute;
    padding-bottom: 15%;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: black;
}

.centrador {
    margin: auto;
    padding-bottom: 10%;
}

.loader {
    position:relative;
      width:30px;
      height:20px;
  
      animation:infinite-spinning 4s infinite;
      animation-timing-function:linear;
      transform-origin:335% 500%;
  }
  
  @keyframes infinite-spinning {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
  }
  
  .moon {
    position: relative;
    margin: auto;
    width: 200px;
    height: 200px;
    background: #c7cbd0;
    border-radius: 50%;
    box-shadow: inset -25px 0px 0 0px #9098a1;
    transition: transform 0.2s ease-in-out;
  }
  
  .moon:hover {
    transform: scale(1.2);
  }


  li.listyle {
    position: absolute;
    list-style: none;
    background: #737277;
    border-radius: 50%;
  }
  li:nth-child(1).listyle {
    left: 25px;
    top: 60px;
    width: 50px;
    height: 50px;
    box-shadow: inset 6px -2px 0 0px #414043;
  }
  li:nth-child(2).listyle {
    left: 150px;
    top: 50px;
    width: 25px;
    height: 25px;
    box-shadow: inset 3px -1px 0 0px #414043;
  }
  
  li:nth-child(3).listyle {
    left: 100px;
    top: 150px;
    width: 25px;
    height: 25px;
    box-shadow: inset 3px -1px 0 0px #414043;
  }
  
  li:nth-child(4).listyle {
    left: 50px;
    top: 150px;
    width: 12.5px;
    height: 12.5px;
    box-shadow: inset 2.4px -0.8px 0 0px #414043;
  }
  
  li:nth-child(5).listyle {
    left: 87.5px;
    top: 16.66666667px;
    width: 12.5px;
    height: 12.5px;
    box-shadow: inset 2.4px -0.8px 0 0px #414043;
  }
  
  li:nth-child(6).listyle {
    left: 114.28571429px;
    top: 80px;
    width: 12.5px;
    height: 12.5px;
    box-shadow: inset 2.4px -0.8px 0 0px #414043;
  }
  
  li:nth-child(7).listyle {
    left: 181.81818182px;
    top: 100px;
    width: 12.5px;
    height: 12.5px;
    box-shadow: inset 2.4px -0.8px 0 0px #414043;
  }
  