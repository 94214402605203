.selector {
    filter: invert(1);
    margin-top: 2rem;
    align-content: center;
    margin-right: auto;
    margin-left: auto;
}

.selector_video_apertura {
    width: 100%;
    bottom: 0;
    position: absolute;
    filter: invert(1);
    z-index: 2000;
}